import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body p-0" }
const _hoisted_3 = { class: "card-px text-center py-20 my-10" }
const _hoisted_4 = { class: "fs-2x fw-bolder mb-10" }
const _hoisted_5 = { class: "text-gray-400 fs-5 fw-bold mb-13" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["data-bs-target"]
const _hoisted_8 = { class: "text-center px-5" }
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_5, [
          _createElementVNode("span", { innerHTML: _ctx.description }, null, 8, _hoisted_6)
        ]),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-primary er fs-6 px-8 py-4",
          "data-bs-toggle": "modal",
          "data-bs-target": `#${_ctx.modalId}`
        }, _toDisplayString(_ctx.buttonText), 9, _hoisted_7)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("img", {
          src: _ctx.image,
          alt: "",
          class: "mw-100 mh-300px"
        }, null, 8, _hoisted_9)
      ])
    ])
  ]))
}