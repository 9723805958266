import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "kt_modal_share_earn",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-800px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header pb-0 border-0 justify-content-end" }
const _hoisted_5 = {
  class: "btn btn-sm btn-icon btn-active-color-primary",
  "data-bs-dismiss": "modal"
}
const _hoisted_6 = { class: "svg-icon svg-icon-1" }
const _hoisted_7 = { class: "modal-body scroll-y pt-0 pb-15" }
const _hoisted_8 = { class: "mw-lg-600px mx-auto" }
const _hoisted_9 = { class: "mb-10" }
const _hoisted_10 = { class: "d-flex" }
const _hoisted_11 = {
  ref: "inputRef",
  id: "kt_share_earn_link_input",
  type: "text",
  class: "form-control form-control-solid me-3 flex-grow-1",
  name: "search",
  value: "https://keenthemes.com/?ref=skitechnology"
}
const _hoisted_12 = {
  ref: "copyButtonRef",
  id: "kt_share_earn_link_copy_button",
  class: "btn btn-light fw-bolder flex-shrink-0",
  "data-clipboard-target": "#kt_share_earn_link_input"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "mb-13 text-center" }, [
              _createElementVNode("h1", { class: "mb-3" }, "Share & Earn"),
              _createElementVNode("div", { class: "text-gray-400 fw-bold fs-5" }, [
                _createTextVNode(" If you need more info, please check "),
                _createElementVNode("a", {
                  href: "#",
                  class: "link-primary fw-bolder"
                }, "Author Commision"),
                _createTextVNode(". ")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_9, [
              _cache[0] || (_cache[0] = _createElementVNode("h4", { class: "fs-5 fw-bold text-gray-800" }, " Share my referral link with friends ", -1)),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("input", _hoisted_11, null, 512),
                _createElementVNode("button", _hoisted_12, " Copy Link ", 512)
              ])
            ]),
            _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"d-flex\"><a href=\"#\" class=\"btn btn-light-primary w-100\"><img alt=\"Logo\" src=\"media/svg/brand-logos/google-icon.svg\" class=\"h-15px me-3\"> Import Contacts </a><a href=\"#\" class=\"btn btn-icon btn-facebook w-100 mx-6\"><img alt=\"Logo\" src=\"media/svg/brand-logos/facebook-2.svg\" class=\"h-20px me-3\"> Facebook </a><a href=\"#\" class=\"btn btn-icon btn-twitter w-100\"><img alt=\"Logo\" src=\"media/svg/brand-logos/twitter.svg\" class=\"h-20px me-3\"> Twitter </a></div><div class=\"d-flex align-items-center mt-10\"><div class=\"flex-grow-1\"><span class=\"fs-6 fw-bold text-gray-800 d-block\">Adding Users by Team Members</span><span class=\"fs-7 fw-bold text-gray-400\">If you need more info, please check budget planning</span></div><label class=\"form-check form-switch form-check-custom form-check-solid\"><input class=\"form-check-input\" type=\"checkbox\" value=\"1\" checked=\"checked\"><span class=\"form-check-label\"> Allowed </span></label></div>", 2))
          ])
        ])
      ])
    ])
  ]))
}